<template>
    <div class="scenery-more">
        <slideshow :slideshowList="slideshowList" @gotodetail="handleGo" ></slideshow>
        <img class="debris" src="../../static/scenery/debris.png" />
        <div class="scenery-content">
            <div class="title">
                <img class="home-icon" src="../../static/scenery/home.png" alt="" style="width:20;height:20;">
                <span style="margin-right:6px;font-weight:bold" @click="Jump('/')">首页</span>
                >
                <span style="margin-left:6px;" @click="Jump('/about')">走进条子泥</span>
                >
                <span style="margin-left:6px;color:#509AB1">条子泥风光</span>
                <!-- >
                <span style="margin-left:6px;color:#509AB1">{{crumbsList}}</span> -->
            </div>
            <el-tabs v-model="activeName" @tab-click="handleClick"  style="width: 100%;" >
                <el-tab-pane 
                    v-for="(item,index) in imageTitleList" :key="index" :label="item.catalogueName" :name="item.id"
                >
                    <p class="image">{{item.catalogueName}}</p>
                    <div class="content-box">
                        <div class="content-item" v-for="item in pictureList" :key="item.id">
                            <div class="bird-box"  
                                :style="{'background-image': 'url(' + item.picUrl + ')',}"
                            >
                                <img src="~static/scenery/brid-box.png" style="opacity:0.7" alt="">
                            </div>
                            <p class="author">作者：{{item.picContent}}</p>
                        </div>
                        <!-- <div class="content-item">
                            <div style="position: relative;height:459px">
                                <img src="../../static/scenery/szh.png" alt="">
                                <div class="box-border1"></div>
                            </div>
                            itemList picUrl pictureList
                            <p class="author">作者：张三</p>
                        </div> -->
                        <!-- <div class="content-item" v-for="(item,index) in pictureList" :key="index">
                            <div class="item-images" >
                                <img :src="item.picUrl" alt="">
                                <div class="box-border1"></div>
                            </div>
                            <p class="author">作者：{{item.picContent}}</p>
                        </div> -->
                    </div>
                    <el-pagination
                        background
                        prev-text="上一页"
                        next-text="下一页"
                        :current-page="currentPage"
                        @current-change="handleCurrentChange"
                        layout="total, prev, pager, next"
                        :page-size="pageSize"
                        :total="totalNum">
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>
            <div ref="backTopBG" style="position: fixed;
                display: none;
                right: 56px;
                color: #509AB1;
                font-weight: bold;
                text-align: center;
                bottom: 130px;
                z-index: 9;" @click="backTop()">
                <img src="~static/backTopbg.png" alt="">
                <p>回到顶部</p>
            </div>
        </div>
    </div>
</template>
  
<script>
import { slideshow } from '@/api/test/test'
import { tzn_tplbAPI, tzn_tpxqAPI } from '@/api/test/scenery'
export default {
    name:'SceneryMore',
    components:{},
    data(){
        return {
            slideshowList:[],
            imageTitleList:[],
            // activeName:'402880447ba001ab017ba0096fdb005f',
            activeName:this.$route.query.id,
            // crumbsList:'条子泥鸟类',
            // 分页
            currentPage: 1,
            pageSize:15,
            totalNum:null,
            pictureList:[],
        }
    },
    created(){},
    mounted(){
        //头部轮播图
        slideshow().then(res => {
            this.slideshowList = res
            console.log(this.slideshowList);
        })
        //tabsTitle
        tzn_tplbAPI().then(res => {
            this.imageTitleList = res
            // console.log(this.imageTitleList,'-----');
        })
        //图片List
        this.imagesData()
    },
    destroyed() {
    },
    methods:{
        //teb切换
        handleClick(e){
            this.crumbsList = e.label
            this.currentPage = 1
            this.imagesData()
        },
        imagesData(){
            tzn_tpxqAPI({
                isUse:1,
                catalogueId:this.activeName,
                size:this.pageSize,
                page:this.currentPage-1,
            }).then(res => {
                this.pictureList = res.data
                this.totalNum = res.count
            })
        },
        //监听轮动条
        backTop() {
            const that = this;
            let ispeed = Math.floor(that.scrollTop / 5);
            document.documentElement.scrollTop = document.body.scrollTop =
                that.scrollTop + ispeed;
            if (that.scrollTop === 0) {
                clearInterval(timer);
            }
        },
        //分页
        handleCurrentChange(val) {
            this.currentPage = val
            this.imagesData()
            console.log(`当前页: ${val}`);
        },
        //头部轮播图跳转
        handleGo(jumpPath){
        // console.log(jumpPath,'ccc');
        // this.$router.push(jumpPath)
        window.location.href = jumpPath
        },
        //数据格式化
        decodeHTML(str) {
            var s = "";
            if (!str || str.length == 0) return "";
                s = str.replace(/&amp;/g, "&");
                s = s.replace(/&lt;/g, "<");
                s = s.replace(/&gt;/g, ">");
                s = s.replace(/&nbsp;/g, " ");
                s = s.replace(/&#39;/g, "\'");
                s = s.replace(/&quot;/g, "\"");
                return s;
        }
    },
}
</script>

<style scoped>
.scenery-more{
    background: url('../../static/scenery/backgd.png') ;
}
/deep/.el-image{
    width: 100%;
}
/deep/ .el-carousel__indicators{
    bottom: 5%;
}
.debris{
    width: 100%;
    margin-top: -157px;
    z-index: 8;
    background: transparent;
    position: relative;
}
.title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 92px;
    background-image: url('../../static/scenery/box-border.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: left;
    line-height: 92px;
    font-size: 24px;
    letter-spacing: 5px;
}
.home-icon{
    width: 20px;
    height: 20px;
    margin: 0 16px 0 53px;
}
.title>span:hover{
    cursor: pointer;
    color: #509AB1;
}
.image{
    width: 450px;
    height: 250px;
    background-image: url('../../static/scenery/img-box.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 250px;
    color: #509AB1;
    margin: 55px auto;
    letter-spacing: 10px;
    font-size: 42px;
    color: #509AB1;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}
/deep/ .el-tabs__nav-wrap{
    overflow: visible;
}
/deep/ .el-tabs__nav{
    position: absolute;
    top: -70px;
    right: 3%;
}
/deep/ .el-tabs__item{
    background-image: url('../../static/viewTabsAC.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #F6D397;
    margin: 0 15px;
    box-sizing: border-box;
    padding: 0 30px !important;
    height: 45px;
    line-height: 45px;
    font-size: 24px;
}
/* /deep/ .el-tabs__item:nth-of-type(2){
    margin-left: 190px;
}
/deep/ .el-tabs__item:nth-of-type(4){
    margin-right: 190px;
} */
/deep/ .el-tabs__item.is-active{
    background-image: url('../../static/viewTabs.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #5CA0B3;
}
/deep/ .el-tabs__active-bar{
    display: none;
}
/deep/ .el-tabs__nav-wrap::after{
    display: none;
}
.content-box{
    width: 65%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-left: 50px;
}
.content-item{
    margin: 8px;
    width: 30%;
}
.bird-box{
    position: relative;
    background-repeat: no-repeat;
    background-size: 98% 98%;
}
.bird-box>img{
    display: block;
    width: 100%;
    height: auto;
}
.author{
    width: 100%;
    height:70px;
    font-size: 18px;
    font-weight: bold;
    color: #509AB1;
    line-height: 50px;
    text-align: center;
    background-image: url('~static/scenery/img-author.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.el-pagination{
    display: flex;
    justify-content: center;
    margin: 18px 0 51px;
}
/deep/ .el-pagination button, .el-pagination span{
    font-size: 16px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #509AB1;
}
/deep/ .el-pagination button:hover{
    color: #509AB1;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #509AB1;
}
</style>
  